<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row justify="space-around" style="word-break: break-word">
              <v-col md="6"  class="text-justify text-center" align-self="center">
               <div class="text-h4 text-center mb-8">Polityka Prywatności</div>
               Niniejsza Polityka Prywatności informuje o zasadach przetwarzania danych osobowych przez Platforma
               Biznesu sp. z o. o.. Dane osobowe są przetwarzane przez Platforma Biznesu sp. z o. o. na zasadach
               określonych w rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
               r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
               swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
               ochronie danych) oraz w polskich przepisach wydanych w związku z obowiązywaniem RODO.

               <div :class="captionStyle">Administrator danych osobowych</div>
                Administratorem danych osobowych jest Platforma Biznesu sp. z o.o. z siedzibą w Szczecinie przy ul.
                Królowej Korony Polskiej 2/2, KRS: 0000404732, NIP 851-31-61-089, REGON 321166829.

                <div :class="captionStyle">Cele i podstawy przetwarzania danych</div>

                Platforma Biznesu sp. z o. o. zbiera i przetwarza dane osobowe uzyskane od klientów, dostawców lub
                innych stron trzecich, w ramach swojej działalności:

                <ol>
                  <li>
                    w celu wykonywania umów z podmiotami współpracującymi z Platforma Biznesu sp. z o. o. (art. 6
                    ust. 1 lit. b RODO), gdzie jako umowę rozumiemy akceptację warunków świadczenia danej
                    usługi;
                  </li>
                  <li>
                    w celu realizacji prawnie uzasadnionych interesów przez Platforma Biznesu sp. z o. o., m.in. w
                    celu świadczenia usług klientom,w celach marketingu bezpośredniego, oraz dla zapewnienia
                    bezpieczeństwa IT, a także w celu dochodzenia roszczeń lub obrony przed roszczeniami (art. 6
                    ust. 1 lit. f RODO);
                  </li>
                  <li>
                    w celu realizacji procesów rekrutacji (art. 6 ust. 1 lit. c oraz art. 6 ust. 1 lit. a RODO);
                  </li>
                  <li>
                    w celu wypełnienia obowiązków prawnych (art. 6 ust. 1 lit. c RODO).
                  </li>
                </ol>

                <div :class="captionStyle">Okres przechowywania danych</div>
                Okres przechowywania i przetwarzania danych zależy od rodzaju wykonywanej umowy. Dane będą
                przetwarzane do chwili upływu terminów przedawnienia związanych z umową, do chwili zrealizowania
                prawnie uzasadnionych interesów Platforma Biznesu sp. z o. o., lub przez czas wymagany przepisami
                prawa.

                <div :class="captionStyle">Odbiorcy danych</div>
                Dane osobowe zgromadzone na potrzeby realizacji świadczenia usługi, mogą zostać ujawnione osobom
                upoważnionym do ich przetwarzania z tytułu wykonywania swoich obowiązków służbowych. Do danych
                mogą mieć również dostęp podmioty trzecie, świadczące usługi niezbędne do wykonania umowy,
                wliczając obsługę IT, obsługę księgową i podatkową czy usługi profesjonalnego niszczenia dokumentacji
                i nośników danych.

                <div :class="captionStyle">Przysługujące prawa</div>
                Osobom udostępniającym dane przysługuje:
                <ul>
                  <li>prawo dostępu do swoich danych oraz otrzymania ich kopii;</li>
                  <li>prawo do sprostowania (poprawiania) swoich danych;</li>
                  <li>prawo do usunięcia danych, jeżeli Państwa zdaniem nie ma podstaw do tego, żebyśmy je
                    przetwarzali;</li>
                  <li>prawo ograniczenia przetwarzania danych;</li>
                  <li>prawo do przenoszenia danych;</li>
                  <li>prawo cofnięcia zgody (jeżeli przetwarzanie danych odbywa się na podstawie zgody);</li>
                  <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych;</li>
                  <li>prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych lub innego
                    właściwego organu nadzorczego, jeżeli uważają Państwo, że przetwarzanie danych osobowych
                    narusza przepisy prawa.
                  </li>
                </ul>
                <br />
                W celu wyegzekwowania swoich praw prosimy o skierowanie żądania pod adres e-mail:
                <b>info@platformabiznesu.net</b>

                <div :class="captionStyle">Pozostałe informacje</div>
                Podanie przez Państwa danych osobowych jest dobrowolne, ale jest potrzebne do zawarcia i wykonania
                umów lub prowadzenia przez Platforma Biznesu sp. z o. o. działań marketingowych.

                <div :class="captionStyle">Zmiany Polityki Prywatności</div>
                Wszelkie zmiany Polityki Prywatności będą publikowane na stronie
                <a href="https://platformabiznesu.net/privacy">https://platformabiznesu.net/privacy</a>

             </v-col>
           </v-row>

          </v-container>


        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {

  data() {
    return {
      captionStyle: "text-h6 mt-8 mb-3"
    }
  },
}

</script>
